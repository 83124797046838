<template>
  <div>
    <v-text-field
      filled
      :dense="dense"
      :suffix="suffix"
      :error="error"
      :placeholder="placeholder"
      :disabled="disabled"
      :label="label"
      :rules="defaultRules"
      hide-details="auto"
      :persistent-hint="persistentHint"
      :persistent-placeholder="persistentPlaceholder"
      prepend-inner-icon="mdi-clock"
      v-model="localValue"
      :color="color"
      @input="handleChange"
    ></v-text-field>
  </div>
</template>

<script>
import dateUtils from '@/mixins/common/dateUtils.js'

export default {
  name: "DurationPicker",
  mixins: [dateUtils],
  data: function() {
    return {
      localValue: this.value,
      error: false,
      mustBeNumberOrUndefined: (value) => {
        if(value == '') {
          return 'Insert number'
        } else {
          let isnum = /^\d+$/.test(value);

          if(!isnum) {
            return 'Not valid number'
          } else {
            return true
          }
        }
      }
    }
  },
  props: {
    dense: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
    },
    days: {
      type: Boolean,
      default: false
    },
    label: {
      type: String
    },
    placeholder: {
      type: String
    },
    persistentHint: {
      type: Boolean,
      default: false
    },
    persistentPlaceholder: {
      type: Boolean,
      default: false
    },
    value: { },
    rules:{
      type: Array,
      default: () => []
    }
  },
  methods: {
    handleChange() {
      this.$emit('input', this.localValue)
    },
  },
  watch: {
    value(newVal) {
      this.localValue = newVal
    }
  },
  computed: {
    suffix() {
      if(this.days) {
        return this.humanReadableDays(this.localValue)
      } else {
        return this.humanReadableMinutes(this.localValue)
      }
    },
    defaultRules() {
      if (!this.rules || this.rules.lenght === 0)
        return [this.mustBeNumberOrUndefined]
      else {
        return this.rules
      }
    }
  }
}
</script>

<style>

</style>